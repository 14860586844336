import React from "react";
import { Link, graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import Img from "gatsby-image";
import productinnovation from "../../images/productinnovation-hero.jpg";
import cloudmanaged from "../../images/cloud-managed-services-1024x680.png";
import appsupport from "../../images/app-support-1024x576.png";
import devops from "../../images/dev-ops-e1573709429999-1024x512.png";
// import comment from '../../images/comment.png';
import ContactUs from "../../components/ContactUs";

const Managedservices = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        title="Managed Services"
        description="iTelaSoft's Managed Services are
                focused on delivering the following as a service to multiple
                regions such as Australia, UK, and North America who are looking
                for best in class solutions and expertise."
      />
      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">Managed Services</h1>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-6 section-2">
        <div className="row">
          <div className="col-12 mt-4 text-center">
            <div className="sub-title-one">Managed Services</div>
            <hr />
            <div className="discription">
              <p>
                Whether your business is small, medium or large in size it is
                running on information wherever you are. Without properly
                structuring, it will be open for security threats, data losses,
                slow performance and costly downtime. Our Managed Services are
                focused on delivering the following as a service to multiple
                regions such as Australia, UK, and North America who are looking
                for best in class solutions and expertise.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="product innovation" className="img-inno" src={productinnovation} />
          </div>
          <div className="col-12 col-md-6">
            <div className="space">
              <h4>Database Administrator Services</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Almost every business that’s backed by IT has a database
                  behind the backdrop. Regardless your business is a startup, or
                  a well established enterprise, health and reliability of your
                  database systems is a critical factor in your business. Poorly
                  managed databases cause significant financial, opportunity and
                  reputation costs. iTelaSoft Database Support Services caters
                  both small and large businesses to keep their data secure and
                  available while managing their costs.
                </p>
              </div>
              <div className="btn btn-outline-primary mt-3">
                <a
                  href="/services/database-services/"
                  rel="noreferrer noopener"
                  aria-label="database-services"
                  target="_blank"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 p-0 order-md-first order-last">
            <div className="space">
              <h4>Cloud Managed Services</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  iTelaSoft Cloud Managed Services support you to build faster,
                  more resilient and more secure cloud solutions. We
                  cost-effectively perform initial research, identify market
                  goals, and work with emerging technologies to build innovative
                  products on behalf of our customers.
                </p>
              </div>
              <div className="btn btn-outline-primary mt-3">
                <a
                  href="/services/cloud-services/"
                  aria-label="cloud-services"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="cloud managed" className="img-inno" src={cloudmanaged} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="app support"  className="img-inno" src={appsupport} />
          </div>
          <div className="col-12 col-md-6 p-0">
            <div className="space">
              <h4>Application Infrastructure and Support</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Server management services continually monitor and manage your
                  UNIX, Microsoft Windows, and Linux servers to ensure optimum
                  performance, uptime and minimize disruption. Your IT servers
                  will be monitored at our network operations center (NOC) which
                  operates 24×7 and uses established procedures to manage,
                  support, and deploy clients’ servers worldwide with suitable
                  SLAs.
                </p>
              </div>
              {/* <div className="btn btn-primary mt-3">Read More</div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 p-lg-0 order-md-first order-last">
            <div className="space">
              <h4>DevOps Pipeline</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  A CI/CD pipeline helps you automate steps in your software
                  delivery process, such as initiating code builds, running
                  automated tests, and deploy to a staging or production
                  environment. Automated pipelines remove manual errors, provide
                  standardized development feedback loops and enable fast
                  product iteration. We, iTelaSoft provide end-to-end CI/CD
                  solutions using following technologies;{" "}
                </p>
                <div className="d-flex ju">
                  <ul>
                    <li>BitBucket</li>
                    <li>Gitlab</li>
                  </ul>
                  <ul>
                    <li>Jenkins</li>
                    <li>AWS CodePipeline</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="devops" className="img-inno" src={devops} />
          </div>
        </div>
        <div className="row wrap-contact-us">
          <ContactUs />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "managed-services-1024x528.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Managedservices;
